define("ember-svg-jar/inlined/expert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.57 25.544a7.38 7.38 0 007.283-8.58 2.459 2.459 0 014.294 0 7.38 7.38 0 009.531 8.231c.31-.278.537-.647.642-1.066l.56-2.237a5.143 5.143 0 11.692-.816c.198.025.384.158.444.398l.508 2.03a7.358 7.358 0 002.285-5.339c0-.564-.063-1.112-.183-1.64.447 0 1-.242 1.218-.632a1.23 1.23 0 00-1.076-1.828h-1.202a7.372 7.372 0 00-6.137-3.28 7.372 7.372 0 00-6.136 3.28h-6.585a7.372 7.372 0 00-6.137-3.28 7.372 7.372 0 00-6.137 3.28H1.232a1.23 1.23 0 00-1.075 1.828c.216.39.77.632 1.217.632a7.38 7.38 0 007.197 9.02zm5.251-7.366a5.143 5.143 0 11-10.285 0 5.143 5.143 0 0110.285 0z\" fill=\"#70E1FF\"/><path d=\"M32.084 25.278l-.362-1.42c-.074-.287-.49-.287-.563 0l-.362 1.42a1.149 1.149 0 01-.844.827l-1.448.355a.283.283 0 000 .552l1.448.355c.416.102.74.42.844.828l.362 1.419c.073.287.49.287.563 0l.361-1.42a1.15 1.15 0 01.845-.827l1.447-.355a.283.283 0 000-.552l-1.447-.355a1.15 1.15 0 01-.844-.827z\" fill=\"#70E1FF\"/>",
    "attrs": {
      "width": "36",
      "height": "37",
      "viewBox": "0 0 36 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});