define("ember-svg-jar/inlined/gem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.523 5.125l-2.5 2.25h12.954l-2.5-2.25H6.523zm10.904 3.857h-3.178l-1.84 6.439 5.018-6.439zM10.5 16.253l2.078-7.27H8.422l2.078 7.27zm-3.749-7.27H3.573l5.018 6.438-1.84-6.439zM5.473 3.906c.279-.25.64-.39 1.015-.39h8.024c.375 0 .736.14 1.015.39l4.082 3.674a.804.804 0 01.096 1.091l-8.571 11a.804.804 0 01-1.268 0l-8.571-11a.804.804 0 01.096-1.09l4.082-3.675z\" fill=\"#F9FAFC\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});